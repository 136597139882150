import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DeleteButton,
  TopToolbar,
  Button,
  useRedirect,
  required,
  Toolbar,
  SaveButton,
  SelectInput
} from 'react-admin';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TIUV_SHEMOT_CATEGORY_CHOICES, TIEV_SHEMOT_LABELS } from '../constants';

const EditActions = ({ basePath, data, resource }) => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <Button
        label="חזרה לרשימה"
        onClick={() => redirect('/TiuvShemot')}
        startIcon={<ArrowBackIcon />}
      />
      <DeleteButton
        basePath={basePath}
        record={data}
        resource={resource}
        confirmTitle="האם אתה בטוח?"
        confirmContent="האם אתה בטוח שברצונך למחוק רשומה זו?"
      />
    </TopToolbar>
  );
};

const TiuvShemotTitle = ({ record }) => {
  return <span>טיוב שמות: {record ? record.name : ''}</span>;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine || props.invalid} />
  </Toolbar>
);

const TiuvShemotEdit = (props) => {
  return (
    <Edit title={<TiuvShemotTitle />} actions={<EditActions />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} autoComplete="off">
        <TextInput
          source="name"
          label={TIEV_SHEMOT_LABELS.NAME}
          disabled
          autoComplete="off"
        />
        <TextInput
          source="alias"
          label={TIEV_SHEMOT_LABELS.ALIAS}
          validate={[required()]}
          autoComplete="off"
        />
        <SelectInput
          source="category"
          label={TIEV_SHEMOT_LABELS.CATEGORY}
          choices={TIUV_SHEMOT_CATEGORY_CHOICES}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default TiuvShemotEdit;
