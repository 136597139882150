import { useState } from 'react';

const useOperation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const run = async (operation) => {
    setIsLoading(true);
    try {
      const result = await operation();
      return result || { success: true };
    } catch (error) {
      return { success: false, error };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    run
  };
};

export default useOperation;
