export const TIUV_SHEMOT_CATEGORIES = {
  TRUCK_MAKE: 'truck_make',
  TRUCK_MODEL: 'truck_model'
};

export const TIUV_SHEMOT_CATEGORY_LABELS = {
  [TIUV_SHEMOT_CATEGORIES.TRUCK_MAKE]: 'יצרן משאית',
  [TIUV_SHEMOT_CATEGORIES.TRUCK_MODEL]: 'דגם משאית'
};

// Reverse mapping from Hebrew labels to category IDs
export const TIUV_SHEMOT_CATEGORY_LABELS_REVERSE = Object.entries(
  TIUV_SHEMOT_CATEGORY_LABELS
).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export const TIUV_SHEMOT_CATEGORY_CHOICES = Object.entries(
  TIUV_SHEMOT_CATEGORY_LABELS
).map(([id, name]) => ({
  id,
  name
}));

export const TIEV_SHEMOT_LABELS = {
  NAME: 'שם נוכחי',
  ALIAS: 'כינוי מסחרי',
  CATEGORY: 'קטגוריה'
};
