import React from 'react';
import { TopToolbar, Button } from 'react-admin';
import { CreateButton } from 'react-admin';
import useListActions from '../hooks/useListActions';
import { Box } from '@material-ui/core';

const ListActions = () => {
  const { isLoading, handleApply, handleExport, handleImport } =
    useListActions();

  return (
    <TopToolbar>
      <Box display="flex" style={{ gap: '10px' }}>
        <Button
          label="עדכן שמות"
          onClick={handleApply}
          disabled={isLoading}
          variant="outlined"
        />
        <input
          type="file"
          accept=".csv"
          onChange={handleImport}
          style={{ display: 'none' }}
          id="csv-import-input"
          aria-label="ייבא מקובץ CSV"
        />
        <Button
          label="ייבא מקובץ CSV"
          onClick={() => document.getElementById('csv-import-input').click()}
          disabled={isLoading}
          variant="outlined"
        />

        <Button
          label="ייצא לקובץ CSV"
          onClick={handleExport}
          disabled={isLoading}
          variant="outlined"
        />
        <CreateButton disabled={isLoading} />
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
