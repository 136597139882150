import { gql } from 'apollo-client-preset';
import { API_URL } from '../../../constants/constants';
import buildApolloClient from '../../../shared-components/ra-data-postgraphile/buildApolloClient';

const client = buildApolloClient({ uri: API_URL });

const useTiuvShemotApi = () => {
  const applyTiuvShemot = async () => {
    const result = await applyTiuvShemotQuery();
    return result;
  };

  const importTiuvShemot = async (input) => {
    const result = await importTiuvShemotQuery(input);
    return result;
  };

  return {
    applyTiuvShemot,
    importTiuvShemot
  };
};

/**
 * Apply Tiuv Shemot
 * @param {import('apollo-client').ApolloClient} client
 * @returns {Promise<{success: boolean, errorMessage: string}>}
 */
const applyTiuvShemotQuery = async () => {
  const response = await client.mutate({
    mutation: gql`
      mutation ApplyTiuvShemot {
        applyTiuvShemot {
          success
          errorMessage
        }
      }
    `
  });

  return {
    success: response.data.applyTiuvShemot?.success || false,
    errorMessage: response.data.applyTiuvShemot?.errorMessage
  };
};

/**
 * Import Tiuv Shemot
 * @param {import('apollo-client').ApolloClient} client
 * @param {object} input
 * @returns {Promise<{success: boolean, errorMessage: string}>}
 */
const importTiuvShemotQuery = async (input) => {
  const response = await client.mutate({
    mutation: gql`
      mutation ImportTiuvShemot($input: [ImportTiuvShemotItem!]!) {
        importTiuvShemot(input: $input) {
          success
          errorMessage
        }
      }
    `,
    variables: { input }
  });

  return {
    success: response.data.importTiuvShemot?.success || false,
    errorMessage: response.data.importTiuvShemot?.errorMessage
  };
};

export default useTiuvShemotApi;
