import { parseCsv } from '../../../utils/csv';
import { readFile } from '../../../utils/file';
import { TIUV_SHEMOT_CATEGORY_LABELS_REVERSE } from '../constants';
import useTiuvShemotApi from './useTiuvShemotApi';

export const useTiuvShemotImpot = () => {
  const { importTiuvShemot: importTiuvShemotApi, applyTiuvShemot } =
    useTiuvShemotApi();

  /**
   * Import Tiuv Shemot
   * @param {File} file
   * @returns {Promise<{success: boolean, errorMessage: string}>}
   */
  const importTiuvShemot = async (file) => {
    try {
      const csv = await readFile(file);
      if (!csv) {
        return { success: false, errorMessage: 'קובץ ריק' };
      }
      const { valid, invalids } = parseInput(csv);

      if (!valid.length) {
        return {
          success: false,
          errorMessage: 'קובץ ריק או שורות שגויות: ' + invalids.length
        };
      }
      let result = await importTiuvShemotApi(valid);
      if (!result.success) {
        console.error('importTiuvShemotApi', result);
        return {
          success: false,
          errorMessage: 'שגיאה בשמירת טיוב שמות: ' + result.errorMessage
        };
      }

      result = await applyTiuvShemot();
      if (!result.success) {
        console.error('applyTiuvShemot', result);
        return {
          success: false,
          errorMessage: 'שגיאה בהצמדת טיוב שמות: ' + result.errorMessage
        };
      }
      return { success: true, errorMessage: null, invalids };
    } catch (error) {
      return { success: false, errorMessage: error.message };
    }
  };

  return {
    importTiuvShemot
  };
};

const mapRow = (row) => {
  return {
    name: row[0]?.trim()?.replace(/'/g, "''"),
    alias: row[1]?.trim()?.replace(/'/g, "''"),
    category: TIUV_SHEMOT_CATEGORY_LABELS_REVERSE[row[2]?.trim()]
  };
};

const isRowValid = (row) => {
  return row.category && row.name && row.alias;
};

const validateRow = (row) => {
  if (!isRowValid(row)) {
    return { success: false, errorMessage: 'שורה שגויה' };
  }
  return { success: true, row };
};

const parseInput = (csv) => {
  const data = parseCsv(csv);
  const input = data.map(mapRow).map(validateRow);
  const valid = input.filter((item) => item.success).map((item) => item.row);
  const invalids = input
    .filter((item) => !item.success)
    .map((item) => item.row);
  return { valid, invalids };
};
