import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  Button,
  TopToolbar,
  useRedirect,
  useNotify,
  required,
  Toolbar,
  SaveButton,
  SelectInput
} from 'react-admin';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TIUV_SHEMOT_CATEGORY_CHOICES, TIEV_SHEMOT_LABELS } from '../constants';

const CreateActions = () => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <Button
        label="חזרה לרשימה"
        onClick={() => redirect('/TiuvShemot')}
        startIcon={<ArrowBackIcon />}
      />
    </TopToolbar>
  );
};

const TiuvShemotTitle = () => {
  return <span>הוספת טיוב שמות חדש</span>;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine || props.invalid} />
  </Toolbar>
);

const TiuvShemotCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      title={<TiuvShemotTitle />}
      {...props}
      onSuccess={() => {
        notify('נוצר בהצלחה', { type: 'success' });
        redirect('/TiuvShemot');
        return false;
      }}
      actions={<CreateActions />}
      onFailure={(error) => {
        console.debug('Error creating record:', error);

        // Check if it's a duplicate key error
        const errorMessage = error.message || '';
        if (
          errorMessage.includes('duplicate key value') ||
          errorMessage.includes('tiuv_shemot_pkey')
        ) {
          notify('שם זה כבר קיים במערכת. אנא בחר שם אחר.', {
            type: 'warning'
          });
          return;
        }

        // For other errors, show the default error message
        notify(errorMessage || 'שגיאה בשמירת הנתונים', {
          type: 'error'
        });
      }}
    >
      <SimpleForm toolbar={<CustomToolbar />} autoComplete="off">
        <TextInput
          source="name"
          label={TIEV_SHEMOT_LABELS.NAME}
          validate={[required()]}
          autoComplete="off"
        />
        <TextInput
          source="alias"
          label={TIEV_SHEMOT_LABELS.ALIAS}
          validate={[required()]}
          autoComplete="off"
        />
        <SelectInput
          source="category"
          label={TIEV_SHEMOT_LABELS.CATEGORY}
          choices={TIUV_SHEMOT_CATEGORY_CHOICES}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export default TiuvShemotCreate;
