/**
 * Convert data to CSV format
 * @param {Array} data - The data to convert
 * @param {Array} colNames - The column names
 * @param {Array} props - The properties to include in the CSV
 * @returns {string} The CSV string
 */
export const toCsv = (data = [], colNames = [], props = []) => {
  const rows = [];
  const headers = colNames.map((col) => col).join(',');
  rows.push(headers);

  data.forEach((item) => {
    const row = props
      .map((prop) => {
        const value = String(item[prop] || '');
        // Wrap in quotes if value contains comma or quotes
        return value.includes(',') || value.includes('"')
          ? `"${value.replace(/"/g, '""')}"`
          : value;
      })
      .join(',');
    rows.push(row);
  });

  return rows.join('\n');
};

/**
 * Parse CSV string
 * @param {string} csv
 * @returns {string[][]} The parsed data
 */
export const parseCsv = (csv) => {
  const lines = csv.split('\n');
  const headers = lines[0].split(',');

  return lines.slice(1).map((line) => {
    const result = [];
    let current = '';
    let inQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        result.push(current);
        current = '';
      } else {
        current += char;
      }
    }

    result.push(current);
    return result;
  });
};
