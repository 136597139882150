import DriveEtaIcon from '@material-ui/icons/DriveEta';
import TiuvShemotList from './components/TiuvShemotList';
import TiuvShemotCreate from './components/TiuvShemotCreate';
import TiuvShemotEdit from './components/TiuvShemotEdit';

/** @type {import('react-admin').ResourceProps} */
export default {
  id: 'name',
  name: 'TiuvShemot',
  list: TiuvShemotList,
  create: TiuvShemotCreate,
  edit: TiuvShemotEdit,
  icon: DriveEtaIcon,
  options: {
    label: 'טיוב שמות - משאיות'
  },
  recordRepresentation: 'name'
};
