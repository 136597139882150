import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import useTiuvShemotApi from './useTiuvShemotApi';
import useOperation from './useOperation';
import useTievShemotExport from './useTievShemotExport';
import { useTiuvShemotImpot } from './useTiuvShemotImpot';

const useListActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { isLoading, run } = useOperation();
  const { applyTiuvShemot } = useTiuvShemotApi();
  const { exportTiuvShemot } = useTievShemotExport();
  const { importTiuvShemot } = useTiuvShemotImpot();

  const handleApply = async () => {
    const result = await run(applyTiuvShemot);
    if (result.success) {
      notify('הטיוב בוצע בהצלחה', { type: 'success' });
      refresh();
    } else {
      notify('שגיאה בביצוע הטיוב', { type: 'error' });
    }
  };

  const handleExport = async () => {
    const result = await run(exportTiuvShemot);
    if (result.success) {
      notify('הייצוא בוצע בהצלחה', { type: 'success' });
    } else {
      notify('שגיאה בייצוא', { type: 'error' });
    }
  };

  /**
   * Import Tiuv Shemot
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleImport = async (event) => {
    const input = event.target;
    const file = input.files[0];
    if (!file) return;

    if (!file.name.endsWith('.csv')) {
      notify('אנא העלה קובץ CSV בלבד', { type: 'warning' });
      input.value = ''; // Reset input on invalid file type
      return;
    }

    const result = await run(() => importTiuvShemot(file));
    if (result.success) {
      if (result.invalids.length) {
        notify(
          'הייבוא בוצע בהצלחה, ישנן שורות שגויות: ' + result.invalids.length,
          { type: 'warning' }
        );
      } else {
        notify('הייבוא בוצע בהצלחה', { type: 'success' });
      }
      refresh();
    } else {
      notify(result.errorMessage, { type: 'error' });
    }

    input.value = ''; // Reset input after operation completes
  };

  return {
    isLoading,
    handleApply,
    handleExport,
    handleImport
  };
};

export default useListActions;
