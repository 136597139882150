import React from 'react';
import { Filter, SelectInput, TextInput, useListContext } from 'react-admin';
import { TIUV_SHEMOT_CATEGORY_CHOICES } from '../constants';
import { TIEV_SHEMOT_LABELS } from '../constants';

const CategoryFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source="name"
        label={TIEV_SHEMOT_LABELS.NAME}
        alwaysOn
        format={(value) => {
          return value ? value.value : null;
        }}
        parse={(value) => {
          const formated = value?.trim();
          return formated
            ? { filter: 'includesInsensitive', value: formated }
            : null;
        }}
      />
      <TextInput
        source="alias"
        label={TIEV_SHEMOT_LABELS.ALIAS}
        alwaysOn
        format={(value) => {
          return value ? value.value : null;
        }}
        parse={(value) => {
          const formated = value?.trim();
          return formated
            ? { filter: 'includesInsensitive', value: formated }
            : null;
        }}
      />
      <SelectInput
        source="category"
        label={TIEV_SHEMOT_LABELS.CATEGORY}
        choices={TIUV_SHEMOT_CATEGORY_CHOICES}
        alwaysOn
      />
    </Filter>
  );
};

export default CategoryFilter;
