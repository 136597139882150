import { useDataProvider } from 'react-admin';
import { TIEV_SHEMOT_LABELS, TIUV_SHEMOT_CATEGORY_LABELS } from '../constants';
import { toCsv } from '../../../utils/csv';
import { exportToFile } from '../../../utils/file';

const useTievShemotExport = () => {
  const dataProvider = useDataProvider();

  const exportTiuvShemot = async () => {
    const { data } = await dataProvider.getList('TiuvShemot', {
      pagination: { page: 1, perPage: 25 }
    });

    const formattedData = data.map((item) => ({
      ...item,
      category: TIUV_SHEMOT_CATEGORY_LABELS[item.category]
    }));

    const csv = toCsv(
      formattedData,
      [
        TIEV_SHEMOT_LABELS.NAME,
        TIEV_SHEMOT_LABELS.ALIAS,
        TIEV_SHEMOT_LABELS.CATEGORY
      ],
      ['name', 'alias', 'category']
    );

    exportToFile(
      csv,
      'csv',
      'רשימת טיוב שמות' + new Date().toLocaleDateString()
    );
  };

  return {
    exportTiuvShemot
  };
};

export default useTievShemotExport;
