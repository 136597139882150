import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  DeleteButton,
  useNotify,
  useRefresh
} from 'react-admin';
import CategoryField from './CategoryField';
import CategoryFilter from './CategoryFilter';
import ListActions from './ListActions';
import TiuvShemotTitle from './TiuvShemotTitle';
import { TIEV_SHEMOT_LABELS } from '../constants';
const TiuvShemotList = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDelete = () => {
    notify('נמחק בהצלחה', { type: 'success' });
    refresh();
  };

  return (
    <List
      {...props}
      title={<TiuvShemotTitle />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
      actions={<ListActions />}
      filters={<CategoryFilter />}
    >
      <Datagrid>
        <TextField source="name" label={TIEV_SHEMOT_LABELS.NAME} />
        <TextField source="alias" label={TIEV_SHEMOT_LABELS.ALIAS} />
        <CategoryField source="category" label={TIEV_SHEMOT_LABELS.CATEGORY} />
        <EditButton />
        <DeleteButton
          confirmTitle="האם אתה בטוח?"
          confirmContent="האם אתה בטוח שברצונך למחוק רשומה זו?"
          mutationOptions={{
            onSuccess: handleDelete
          }}
        />
      </Datagrid>
    </List>
  );
};

export default TiuvShemotList;
